import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ObservableStore } from '@codewithdan/observable-store';
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ServiceUtilModule } from "./service-util.module";
import { StoreState } from "./store";

@Injectable({
    providedIn: 'root'
})
export class RefsService extends ObservableStore<StoreState> {

    constructor(
        private http: HttpClient,
        private translate: TranslateService
    ) {
        super({ logStateChanges: true, trackStateHistory: true });
        const initialState = {
        };
        this.setState(initialState, 'INITIALIZE_STORE');
    }

    addBanklist(): void {
        this.http.get<any>(`/api/payment/banklist`).pipe(
            map(response => response.data)
        ).subscribe((bankRefs: any) => this.setState({ bankRefs: bankRefs }, 'UPDATE_BANK_LIST'));
    }

    // getBanklist(): any {
    //     return of(this.getState().bankRefs);
    // }

    getBanklist() {
        return this.http.get<any>(`/api/payment/banklist`).pipe(
            map(response => response.data),
            catchError(ServiceUtilModule.handleError));
    }

    getBankListJson() {
        return this.http
            .get<any>('./assets/data/bank_list.json')
            .pipe(
                catchError(ServiceUtilModule.handleError));
    }
}