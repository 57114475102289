import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroAntdModule } from './shared/ngzorro.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { LoginComponent } from './pages/login/login.component';
import { SecureComponent } from './pages/secure/secure.component';
import { AuthGuard } from './shared/guard/auth.guard';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

const ngZorroConfig: NzConfig = {
  message: {
    nzMaxStack: 1,
    nzAnimate: true
  },
  notification: {
    nzMaxStack: 1,
    nzDuration: 10000,
    nzAnimate: true
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: NZ_CONFIG,
      useValue: ngZorroConfig
    },
    AuthGuard,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
