import * as _ from 'lodash';
import { throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

export namespace ServiceUtilModule {
    export function extractData(res: Response) {
        const body = res.json();
        return body || {};
    }

    export function handleError(error: HttpErrorResponse) {
        return throwError(error);
    }
}
